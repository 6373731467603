<template>
    <div :class="['title',isFixed ? 'title-fixed': '',!isLtr ? 'title-rtl' : '']">

        <button v-if="backButton" @click="goToBack" class="btn btn-back btn-icon btn-round">
            <span class="material-icons-outlined" aria-hidden="true">{{nextArrow}}</span>
        </button>

        <div class="title-bar">
            <div v-if="showButtons" class="btn-group">
                <router-link :to="karteLink" :class="['btn', showMapOrList ? 'btn-active' : '']">Karte</router-link>
                <router-link :to="listeLink" :class="['btn', !showMapOrList ? 'btn-active' : '']">Liste</router-link>
            </div>

            <button @click="activateTermin" v-else-if="showTermin" :class="['btn btn-w-icon icon-left termin', showTerminSelection ? 'btn-active' : '']">
                <span class="material-icons-outlined" aria-hidden="true">schedule</span>
                <span class="label">Termin</span>
            </button>

            <button @click="activateFilter" :class="['btn btn-w-icon icon-left', hasBreadcrumb ? 'ml-auto' : '', showFilterSelection ? 'btn-active': '']">
                <span class="material-icons-outlined" aria-hidden="true">filter_alt</span>
                <span class="label">Filter</span>
            </button>

            <button @click="activateSearch" :class="['btn btn-w-icon icon-left', showTextSearch ? 'btn-active' : '']">
                <span class="material-icons-outlined" aria-hidden="true">search</span>
                <span class="label">Suche</span>
            </button>
        </div>

        <time-filters :initialValueSet="dateTimeSelection" v-show="showTerminSelection" @changedTimeFilters="changedTimeFilters"/>

        <content-filters v-if="showFilterSelection" :filterMenu="filterMenu" 
        :contentCount="contentCount" :filters="filters" :isLtr="isLtr" :showActiveFilters="showActiveFilters"
        :selectedFilters="selectedFilters" @onClose="closeContentFilters" @resetAllFilters="resetAllFilters"
        @filteringFinished="contentFilteringFinished" @getNumberOfResults="getNumberOfResults"/>

        <keyword-filter v-if="showTextSearch" :initialValue="initialKeywordSearchValue" @searchWordChanged="searchWordChanged"/>
        
    </div>
</template>

<script>
export default {
    name: 'TitleBar',
    components: {
        TimeFilters: () => import('./components/TimeFilters'),
        KeywordFilter: () => import('./components/KeywordFilter'),
        ContentFilters: () => import('./components/ContentFilters'),
    },
    props: {
        showButtons: {
            type: Boolean,
        default: false,
        },
        showActiveFilters: {
            type: Boolean,
        default: false,
        },
        karteLink: {
            type: String,
        default: '#!',
        },
        listeLink: {
            type: String,
        default: '#!',
        },
        showTermin: {
            type: Boolean,
        default: false,
        },
        showMapOrList: {
            type: Boolean,
        default: true,
        },
        hasBreadcrumb: {
            type: Boolean,
        default: false,
        },
        filterMenu: {
            type: String,
        default: 'places',
            validator: filterMenu => ['places', 'offers', 'kita', 'schul', 'bookmark'].indexOf(filterMenu) !== -1
        },
        filters: {
            type: Array,
            required: false,
        default: [],
        },
        selectedFilters: {
            type: Map,
        default: new Map(),
        },
        backButton: {
            type: Boolean,
        default: false,
        },
        isLtr: {
            type: Boolean,
        default: true,
        },
        isFixed: {
            type: Boolean,
        default: false,
        },
        initialKeywordSearchValue: {
            type: String,
        default: '',
        },
        contentCount: {
            tyle: Number,
        default: 0
        },
        dateTimeSelection: {
            type: Object,
        default: null
        }
    },
    data() {
        return {
            showTextSearch: false,
            showFilterSelection: false,
            showTerminSelection: false,
        }
    },
    computed: {
        nextArrow() {
            return this.isLtr ? 'arrow_back' : 'arrow_forward';
        },
    },
    methods: {
        activateSearch() {
            this.showFilterSelection = false;
            this.showTerminSelection = false;
            this.showTextSearch = !this.showTextSearch;
        },
        activateFilter() {
            this.showTerminSelection = false;
            this.showTextSearch = false;
            this.showFilterSelection = !this.showFilterSelection;
        },
        activateTermin() {
            this.showTextSearch = false;
            this.showFilterSelection = false;
            this.showTerminSelection = !this.showTerminSelection;
        },
        closeContentFilters(){
            this.activateFilter();
            this.getNumberOfResults(this.selectedFilters);
        },
        contentFilteringFinished(map){
            this.activateFilter();
            this.$emit('contentFilteringFinished',map);
        },
        getNumberOfResults(map){
            this.$emit('getNumberOfResults',map);
        },
        changedTimeFilters(timeObject){
            this.$emit('changedTimeFilters',timeObject);
        },
        searchWordChanged(word){
            this.$emit('searchWordChanged',word);
        },
        resetAllFilters(){
            this.$emit('resetAllFilters');
        },
        goToBack() {
            this.$router.go(-1);
        }
    },
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';
@import '@/scss/_mixins.scss';

.btn-w-icon{

    .label{
     @media (max-width: 450px) {
        display: none;
    }
}

.material-icons-outlined{
 @media (max-width: 450px) {
    margin-right: 0px!important;
}
}
}

.form-check, .form-check-label{
    width: 100%!important;
    cursor: pointer;
}

.title {
    position: relative;
    display: flex;
    align-items: center;
    background: $primary;
    height: $title-bar-size;
    max-height: $title-bar-size;
    width: 100%;
    z-index: 1001;

    &-fixed {
        position: absolute;
        top: 0;
    }

    .btn {
        &-back {
            position: absolute;
            margin-left: 15px;
        }

        &.termin{
            margin-left: 60px;
        }
    }

    @include responsive($breakpoint) {
        display: none;
    }

    &-bar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;

        .btn {
            padding: 0 20px;
            height: $title-bar-size;
            border-radius: 0;
            border: none;
            border-top: 10px solid $primary;
            border-bottom: 10px solid $primary;
            text-transform: unset;

            &-active {
                background: #F9F9F9;
                color: $dark-color;
                border-bottom-color: #F9F9F9;
            }
        }

        .btn-group {
            background-color: $white-color;
            border-radius: 100px;
            padding: 5px;
            margin-left: 70px;
            
            .btn {
                border: none;
                padding: 0 0.75rem;
                height: 30px;
                background-color: transparent;
                color: $dark-color;
                font-weight: 400;
                line-height: 30px;

                &-active {
                    background-color: #e5e4e5;
                    border-radius: 100px !important;
                    font-weight: 700;
                }
            }
        }
    }
}

body[dir="rtl"] { 
    .title{
        .btn {
            &-back {
                margin-left: 0;
                margin-right: 15px;
            }

            &.termin{
                margin-left: 0;
                margin-right: 60px;
            }
        }

        .title-bar {
            .btn-group {
                margin-left: 0;
                margin-right: 70px;
            }
        }
    }
}
</style>
